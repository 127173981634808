@import '~app/mixins';

.session {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .popup {
    width: 460px;
    background-color: white;
    border: 1px solid #666;
    border-radius: 5px;
    color: $color-grey;
    padding: 30px;
    line-height: 21px;

    .warning {
      text-align: center;
      margin-bottom: 10px;

      svg {
        width: 90px;
        height: 90px;
        fill: #f4854f;
      }
    }

    .message {
      white-space: pre-wrap;
    }

    .buttons {
      display: flex;
      justify-content: space-around;
      margin-top: 30px;

      button {
        min-width: 125px;
      }
    }
  }

  &.loggedOut {
    .message {
      text-align: center;
    }
  }
}
